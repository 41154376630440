<template>
	<div class="footer">
		<div class="footer__logo">
			<img :src="logo" alt="Feria Valencia logo" />
		</div>
		<div class="footer__info">
			<span>{{ `®️ ${currentYear} ${$t('footer.rightsreserved')}` }}</span>
		</div>
		<div class="footer__links">
			<ul>
				<li>
					<router-link :to="{ name: 'Cookies' }" class="footer__link">{{ $t('footer.cookiespolicy') }}</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'Privacy' }" class="footer__link">{{ $t('footer.privacepolicy') }}</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'LegalAdvice' }" class="footer__link">{{ $t('footer.legaladvice') }}</router-link>
				</li>
			</ul>
		</div>
		<div class="footer__collaboration">
			<img class="footer__collaboration-img" src="@/assets/images/colabora_dival.png" alt="Diputació de València. Amb la col·laboració." />
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',

	data() {
		return {
			logo: require('../../assets/images/FooterLogo.svg'),
			currentYear: new Date().getFullYear()
		};
	}
};
</script>

<style scoped>
.footer {
	width: 100%;
	background-color: #293133;
	display: flex;
	padding-left: 10%;
	justify-content: space-between;
}

.footer__logo {
	padding: 0.6rem 0;
	display: flex;
}

.footer__info > span,
.footer__link {
	font-family: Roboto;
	font-size: 0.75rem;
	color: #fff;
}

.footer__info {
	display: flex;
	align-items: center;
	padding-left: 10%;
}

.footer__links > ul {
	display: flex;
	list-style-type: none;
}

.footer__links > ul > li {
	display: flex;
	text-align: center;
	align-items: center;
}

.footer__links {
	display: flex;
	align-items: center;
}

.footer__link {
	text-decoration: none;
	padding: 0 0.938rem;
}

.footer__collaboration {
	padding-right: 10%;
	display: flex;
	align-items: center;
	padding-left: 24px;
}

.footer__collaboration-img {
	height: 3rem;
	width: auto;
}
</style>



<template>
	<div class="navbar">
		<div class="navbar__left">
			<div class="navbar__logo-container">
				<img class="navbar__logo" :src="getEventLogo" alt="logo" @click="goToHome()" />
			</div>
			<div class="navbar__menu">
				<ul>
					<li>
						<router-link :to="{ name: 'Exhibitors' }" class="navbar__menu-link">{{ $t('menu.catalogexhibitor') }}</router-link>
					</li>
					<li v-if="showProduct">
						<router-link :to="{ name: 'Products' }" class="navbar__menu-link">{{ $t('menu.productmarketplace') }}</router-link>
					</li>
					<li v-if="showActivity">
						<router-link :to="{ name: 'Activities' }" class="navbar__menu-link">{{ $t('menu.activities') }}</router-link>
					</li>
				</ul>
			</div>
		</div>

		<div class="navbar__right" v-if="user == null">
			<v-menu class="my-menu" offset-y transition="slide-y-transition" bottom left :min-width="175">
				<template :ripple="false" v-slot:activator="{ on, attrs }">
					<!-- <v-btn :ripple="false" id="navbar_id" class="navbar__login" text v-bind="attrs" v-on="on"> Login </v-btn> -->
					<v-btn @click="show = !show" class="ma-2 titulo" outlined color="black" text v-bind="attrs" v-on="on">
						{{ $t('menu.login') }}
						<v-icon x-small>{{ show ? 'fas fa-chevron-up fa-xs icono' : 'fas fa-chevron-down fa-xs icono' }}</v-icon></v-btn
					>
				</template>
				<v-list>
					<router-link class="vitem" :to="{ name: 'Login' }">
						<v-list-item link>
							<v-list-item-title> {{ $t('router.login') }}</v-list-item-title>
						</v-list-item>
					</router-link>
					<router-link class="vitem" :to="{ name: 'SignUp' }">
						<v-list-item link>
							<v-list-item-title> {{ $t('menu.register') }}</v-list-item-title>
						</v-list-item>
					</router-link>
				</v-list>
				<!-- <v-list>
						<v-list-item v-for="item in items" :key="item" link>
							<v-list-item-title v-text="item"></v-list-item-title>
						</v-list-item>
					</v-list> -->
			</v-menu>
			<LanguageSelector />
		</div>

		<div class="navbar__right" v-else>
			<v-menu offset-y transition="slide-y-transition" bottom left :min-width="175">
				<template :ripple="false" v-slot:activator="{ on, attrs }">
					<v-btn @click="show = !show" class="ma-2 titulo" outlined color="black" text v-bind="attrs" v-on="on"
						>{{ user.name }}<v-icon x-small>{{ show ? 'fas fa-chevron-up fa-xs icono' : 'fas fa-chevron-down fa-xs icono' }}</v-icon>
					</v-btn>
				</template>
				<v-list>
					<router-link class="vitem" :to="{ name: 'Interests' }">
						<v-list-item link>
							<v-list-item-title class="vitem" id="click">{{ $t('interests.interest') }}</v-list-item-title>
						</v-list-item>
					</router-link>
					<router-link class="vitem" :to="{ name: 'Visits' }">
						<v-list-item link>
							<v-list-item-title class="vitem" id="click">{{ $t('visits.visit') }}</v-list-item-title>
						</v-list-item>
					</router-link>
					<router-link class="vitem" :to="{ name: 'Appointments' }">
						<v-list-item link>
							<v-list-item-title class="vitem" id="click">{{ $t('appointments.myappointments') }}</v-list-item-title>
						</v-list-item>
					</router-link>
					<router-link class="vitem" :to="{ name: 'MyAccount' }">
						<v-list-item link>
							<v-list-item-title class="vitem"> {{ $t('login.profile') }} </v-list-item-title>
						</v-list-item>
					</router-link>
					<router-link class="vitem" :to="{ name: 'Contact' }" v-if="isLogin">
						<v-list-item link>
							<v-list-item-title class="vitem"> {{ $t('login.contact') }} </v-list-item-title>
						</v-list-item>
					</router-link>
					<router-link class="vitem" :to="{ name: 'PrivacySetting' }" v-if="isLogin">
						<v-list-item link>
							<v-list-item-title class="vitem"> {{ $t('login.privacysetting') }} </v-list-item-title>
						</v-list-item>
					</router-link>
					<v-list-item @click="logOut" link>
						<v-list-item-title class="vitem" id="click">{{ $t('menu.disconnectsession') }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<LanguageSelector />
		</div>

		<!-- <template>
			<v-btn text> Normal </v-btn>
		</template> -->
	</div>
</template>

<script>
import { getDocument } from '@/api/common.js';
import { signOut } from '@/api/sessions.js';
import LanguageSelector from './LanguageSelector.vue';

export default {
	name: 'Navbar',
	components: {
		LanguageSelector
	},

	data() {
		return {
			logo: '',
			items: [{ title: 'Iniciar sesión', router: 'Login' }, { title: 'Registarse' }],
			btns: [['Large', 'lg']],
			colors: ['white'],
			objetos: [...Array(4)].map((_, i) => `Item ${i}`),
			user: '',
			show: false
		};
	},
	mounted() {
		// this.getLogo();
		this.checkIfTokenIsNotEmpty();
		this.splitlocation = window.location.href.split('/');
	},
	methods: {
		async getLogo() {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_catalog_logo');
			this.logo = data[0].url;
		},

		checkIfTokenIsNotEmpty() {
			this.user = JSON.parse(localStorage.getItem('pui9_session'));
		},

		async logOut() {
			const splitlocation = window.location.href.split('/');
			await signOut();
			this.$puiEvents.$emit('onPuiLogout');
			window.localStorage.removeItem('pui9_session');
			window.localStorage.removeItem('workingRole');
			if (this.$route.path.includes('home')) {
				window.location.reload();
			} else {
				window.location.replace(
					window.location.protocol + '//' + splitlocation[2] + '/' + splitlocation[3] + '/' + splitlocation[4] + '/home'
				);
			}
		},

		goToHome() {
			this.$store.dispatch('puiRouterGoToUrl', { router: this.$router, url: `/${this.$store.state.eventInfo.evgrurlname}/home` });
		}
	},
	computed: {
		showProduct() {
			return this.$store.state.eventInfo.evencatalogfilterproduct === 1 ? true : false;
		},
		showActivity() {
			return this.$store.state.eventInfo.evencatalogfilteractivity === 1 ? true : false;
		},
		isLogin() {
			return window.localStorage.getItem('pui9_session') ? true : false;
		},
		getEventLogo() {
			return this.$store.state.eventInfo.eventLogo;
		}
	}
};
</script>

<style scoped>
.navbar {
	height: 5rem;
	padding: 0 2.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media only screen and (max-width: 960px) {
		display: contents;
	}
}

.navbar__left {
	display: flex;
	@media only screen and (max-width: 960px) {
		display: contents;
	}
}

.navbar__logo-container {
	display: flex;
	align-items: center;
}

.navbar__logo {
	max-width: 128px;
	max-height: 64px;
	cursor: pointer;
}

.navbar__menu {
	display: flex;
	align-items: center;
}

.navbar__menu > ul {
	display: flex;
	list-style-type: none;
	@media only screen and (max-width: 960px) {
		padding-left: 0px;
		width: 99%;
	}
}

.navbar__menu > ul > li {
	display: flex;
	text-align: center;
	align-items: center;
}

.navbar__menu-link {
	text-decoration: none;
	font-family: Cabin;
	font-size: 1rem;
	color: #575e60;
	padding: 0 0.938rem;
}

.navbar__right {
	display: flex;
}

.navbar__login {
	text-decoration: none;
	font-family: Cabin;
	font-weight: bold;
	font-size: 1rem;
	color: #575e60;
	padding: 0 0.938rem;
}

.navbar__language {
	border: 1px solid #d2d7d9;
	border-radius: 8px;
	padding: 0 0.938rem;
	height: 30px;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

#click {
	cursor: pointer;
}

.titulo {
	/* Button / Text */

	font-family: Cabin;
	font-style: normal;
	font-weight: 550;
	font-size: 16px;
	line-height: 19px;
	/* Neutral / Dark grey */

	color: #575e60;
}

.vitem {
	text-decoration: none !important;

	font-family: Cabin;
	font-style: normal;
	font-weight: 550;
	font-size: 16px;
	line-height: 19px;

	/* Neutral / Dark grey */

	color: #575e60;
}

.icono {
	margin-left: 5px;
}

.navbar__logo-container {
	@media only screen and (max-width: 960px) {
		padding: 2rem;
	}
}

.navbar__right {
	@media only screen and (max-width: 960px) {
		padding-left: 1.5rem;
	}
}

.text-center.language-container {
	@media only screen and (max-width: 960px) {
		margin-top: 0.5rem;
	}
}
</style>


